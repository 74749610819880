import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ThirdwebProvider, ChainId, } from "@thirdweb-dev/react";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { AuthProvider, } from "./context/UserContext";




const activeChainId = ChainId.Mainnet;


// const connectors = [
//   "metamask",
//   "walletConnect",
//   "walletLink",
//   {
//     name: "magic",
//     options: {
//       apiKey: "pk_live_869D8538865944E0",
//     }
//   }
// ]


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
      <ThirdwebProvider
        desiredChainId={activeChainId}
        //walletConnectors={connectors}
      >

        <BrowserRouter basename="/">
          <App />
        </BrowserRouter>

      </ThirdwebProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
